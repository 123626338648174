<template>
  <div class="pl-10 pr-10 container mx-auto">
    <div class="text-center">
      <img
        class="inline"
        src="https://assets.bearchip.com/bearchip-assets/logo.png"
        alt="logo"
        width="270"
      />
    </div>

    <div class="text-center mb-5 text-3xl font-bold mission">
      <p>
        Search <span class="brush-background">#NoCode</span> tools, bare chip
        fast.
        <a href="https://www.buymeacoffee.com/bearchip" target="_blank"
          ><img
            class="inline"
            src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
            alt="Buy Me A Coffee"
            style="height: 30px !important; border-radius: 1em"
        /></a>
      </p>
    </div>

    <div class="search-container">
      <input
        class="w-full pl-20 pr-10 h-16 border-2 rounded-full"
        id="search"
        type="search"
        autofocus
        placeholder="e.g. Design"
        aria-label="Search NoCode tools, bare chip fast."
        v-model="searchInput"
        @input="search(searchInput)"
      />
    </div>

    <div class="mt-1 text-center text-xs text-gray-400">
      <span class="uppercase"> time spent: {{ time }}ms</span>
      <span> | </span>
      <span>hello@bearchip.com</span>
    </div>
  </div>
  <div class="md:grid gap-3 grid-cols-3 p-5">
    <div
      class="box-border p-5 mb-2 shadow-lg rounded-lg card-background"
      v-for="tool in tools"
      :key="tool.id"
    >
      <Tool
        :name="tool.name"
        :title="tool.title"
        :link="tool.link"
        :description="tool.description"
        :screenshot="tool.screenshot"
        :tags="tool.tags"
      />
    </div>
  </div>
</template>

<script>
import Tool from './components/Tool.vue'

const apiHeaders = new Headers({
  'Content-Type': 'application/json',
  'X-Meili-API-Key':
    'bca21fd682a600db0a6b23970952f48a8e61130db837cf3859dc2d80ba77753a',
})

const api = 'https://bearchip.com/search/indexes/tools/search'

export default {
  name: 'App',
  components: {
    Tool,
  },
  data() {
    return {
      searchInput: '',
      tools: [],
      time: 0,
      count: 0,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      fetch(api, {
        method: 'POST',
        headers: apiHeaders,
        body: `{ "limit": 153 }`,
      })
        .then((response) => response.json())
        .then(({ hits, processingTimeMs, nbHits }) => {
          for (const hit of hits) {
            hit.tags = hit.tags.split(',')
          }
          this.tools = hits
          this.time = processingTimeMs
          this.count = nbHits
        })
        .catch((err) => console.error(err))
    },
    search(searchInput) {
      fetch(api, {
        method: 'POST',
        headers: apiHeaders,
        body: `{ "q": "${searchInput}", "attributesToHighlight": ["*"], "limit": 60 }`,
      })
        .then((response) => response.json())
        .then(({ hits, processingTimeMs, nbHits }) => {
          for (const hit of hits) {
            hit.tags = hit.tags.split(',')
            hit.description = this.sanitizeHTMLEntities(
              hit._formatted.description
            )
            hit.name = this.sanitizeHTMLEntities(hit._formatted.name)
            hit.title = this.sanitizeHTMLEntities(hit._formatted.title)
          }
          this.tools = hits
          this.time = processingTimeMs
          this.count = nbHits
        })
        .catch((err) => console.error(err))
    },
    sanitizeHTMLEntities(str) {
      if (str && typeof str === 'string') {
        str = str.replace(/</g, '&lt;')
        str = str.replace(/>/g, '&gt;')
        str = str.replace(/&lt;em&gt;/g, '<em>')
        str = str.replace(/&lt;\/em&gt;/g, '</em>')
      }
      return str
    },
  },
}
</script>
