<template>
  <div class="mb-3">
    <a :href="link" target="_blank">
      <img
        :src="screenshot"
        alt="Item illustration"
        class="rounded-lg shadow-md"
        :href="link"
      />
    </a>
  </div>
  <div>
    <div>
      <div class="name mb-5" v-html="name"></div>
      <div class="link mb-5">
        <a :href="link" target="_blank">Go to Website</a>
      </div>
      <div class="title mb-5" v-html="title"></div>
      <div class="description mb-5" v-html="description"></div>
      <div class="tag mb-5">
        <span v-for="(tag, index) in tags" :key="index">{{ tag }}</span>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  name: 'Tool',
  props: {
    name: String,
    title: String,
    screenshot: String,
    link: String,
    description: String,
    tags: Array,
  },
}
</script>